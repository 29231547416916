/* eslint-disable no-console */
import React, { useEffect } from 'react';

import './styles/normalize.less';
import {
  BrowserRouter as Router, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
// @ts-ignore
import sha1 from 'sha1';
import HeaderComposed from './components/Header/Header';
import AdminHeader from './components/Header/AdminHeaderLazy';
import Footer from './components/Footer/Footer';
import Spinner from './components/Spinner/Spinner';
import config from './config';
import { fetchNotificationTags, fetchUserProfile } from './store/user/actions';
import { fetchAdminProfile } from './store/admin/actions';
import AdminSection from './pages/admin/AdminSectionLazy';
import CustomerSection from './pages/customer/CustomerSectionLazy';
import PublicSection from './pages/public/PublicSection';
import Message from './components/Message/Message';
import { fetchAppInitialData } from './store/app/actions';
// @ts-ignore
import { pushCriteoRoute } from './counters_and_trackers/criteo';
// @ts-ignore
import impactIdentify from './counters_and_trackers/impact_identify';
import { userSelector } from './store/user/selectors';
import SessionPopupWidget from './components/Popup/SessionPopupWidget';
import ShopifyGateway from './pages/customer/ShopifyGateway/ShopifyGateway';
import { fetchJwtToken } from './services/Customer/userAuthorizedAxios';
import {
  fetchContraceptionTypes, fetchReproductiveStages, fetchHormoneTherapyTypes, fetchBloodData, fetchPhysioData, fetchPhysioResults,
} from './store/reference/actions';
import { fetchAdminJwtToken } from "./services/Admin/adminJwtAuthorizedAxios";
import { fetchLocationData } from './store/common/actions';
import ShopifyStoreRedirecter from './pages/customer/ShopifyGateway/ShopifyStoreRedirecter';
import ErrorBoundary from './pages/public/PageNotFound/ErrorBoundary';

const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    pushCriteoRoute();
  }, [pathname]);

  return null;
};

const RedirectToDashboard = (): null => {
  const user = useSelector(userSelector);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      user
      && !document.referrer.includes(config.hostName)
      && location.pathname === '/') {
      history.push('/customer/summary/dashboard');
    }
  }, []);

  return null;
};

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userId = user?.id;
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const hideNavigation = (new URLSearchParams(location.search)).get('hideNavigation') !== null;

  useEffect(() => {
    (async () => {
      // if (!customerAuthTokenProvider.get()) - we cannot use this to reduce requests number for now,
      // because sometimes system logins customers by cookies which already have token in localstorage
      // we must always get token by cookie for now
      await fetchJwtToken();
      await fetchAdminJwtToken();

      try {
        await Promise.all([
          dispatch(fetchAppInitialData()),
          dispatch(fetchUserProfile()), // do not do this if customerAuthTokenProvider.get() is null
          dispatch(fetchAdminProfile()),
        ]);
        dispatch(fetchBloodData());
        dispatch(fetchLocationData());
        dispatch(fetchContraceptionTypes());
        dispatch(fetchHormoneTherapyTypes());
        dispatch(fetchReproductiveStages());
        setLoading(false);
        dispatch(fetchPhysioData());
        dispatch(fetchPhysioResults());
        dispatch(fetchNotificationTags());
        document.getElementById('cover')
          .remove();
      } catch {
        // no-catch
      }
    })();

    console.log(`Environment: ${config.apiHostName}`);
  }, []);

  useEffect(() => {
    if (user && config.isProduction) {
      setTimeout(() => {
        impactIdentify(sha1(user.email), user.id);
      }, 15000);
    }
  }, [userId]);

  if (loading) return <Spinner show/>;

  // TODO: routes file with array of route object with lazy() import; to can be used check is route exists in react app
  return (
    <>
      <Router>
        <Helmet>
          <title>InsideTracker</title>
          <meta
            name="keywords"
            content="diet analysis, health analysis, health and wellness, health improvement, nutrition analysis, nutritional analysis,
       essentials program, blood analysis, health and wellness plan, athletic essentials, insidetracker, insidetracker.com,
       inside tracker, biomarker analysis, personalized nutrition plan, Segterra"
          />
        </Helmet>
        <ScrollToTop/>
        <RedirectToDashboard/>
        <Spinner show={loading}/>
        <Message/>
        <Switch>
          <Route
            path={[
              "/customer/store/purchased-on-shopify",
              "/shopify-gateway",
            ]}
            exact
          >
            <ShopifyGateway/>
          </Route>
          <Route path="/customer/store/redirect-me-on-shopify" exact>
            <ShopifyStoreRedirecter/>
          </Route>

          <Route path="/tour/proceed">
            <PublicSection/>
          </Route>

          <Route>
            <Switch>
              <Route path="/admin">
                <AdminHeader/>
              </Route>
              <Route>
                {hideNavigation ? null : <HeaderComposed/>}
              </Route>
            </Switch>
            <ErrorBoundary>
              <Switch>
                <Route path="/customer">
                  <CustomerSection/>
                </Route>
                <Route path="/admin">
                  <AdminSection/>
                </Route>
                <Route>
                  <PublicSection/>
                </Route>
              </Switch>
            </ErrorBoundary>
            {hideNavigation ? null : <Footer/>}
          </Route>
        </Switch>
      </Router>
      <SessionPopupWidget/>
    </>
  );
};

export default App;
