import baseAxios from '../baseAxios';
import AuthToken from '../../interfaces/Auth/AuthToken';
import adminAuthAxios from './adminAuthorizedAxios';
import { GetJwtResponse } from '../../interfaces/Auth/JwtToken';

type LoginResponseData = {
  token: AuthToken,
  redirect: string,
  jwtToken: GetJwtResponse,
};

export default {
  getJwtTokenByCookies: (): Promise<GetJwtResponse> => baseAxios.get('/api/admin/auth/jwt-token')
    .then((responseData) => responseData.data),

  loginByEmail: (data: {
    email: string,
    password: string,
    stayLoggedIn: boolean
  }): Promise<LoginResponseData> => baseAxios.post('/api/admin/auth/login', data)
    .then((response) => response.data),

  logout: () => baseAxios.get('/api/admin/auth/logout'),

  // 2FA Phone Flow
  sendSmsVerificationCode: () => adminAuthAxios.get('/api/admin/auth/sendSmsVerificationCode')
    .then((response) => response.data),

  verifyCode: (
    code: string,
  ): Promise<LoginResponseData> => adminAuthAxios
    .post('/api/admin/second-factor-auth/verify-sms-code', { code })
    .then((response) => response.data),

  savePhone: (
    phone: string,
  ): Promise<LoginResponseData> => adminAuthAxios
    .post('/api/admin/auth/savePhone', { phone })
    .then((response) => response.data),

  // 2FA Google Authenticator flow

  googleAuthenticatorQrCodeUri: (): Promise<string> => adminAuthAxios.get('/api/admin/second-factor-auth/google-authenticator-qr')
    .then((response) => response.data.link),

  verifyGoogleAuthenticatorCode: (code: string): Promise<LoginResponseData> => adminAuthAxios
    .get(`/api/admin/second-factor-auth/verify-google-authenticator-code/${code}`)
    .then((response) => response.data),

  // recovery
  sendRecoveryEmail: (data: {
    email: string,
    recaptchaToken: string,
    recaptchaAction: string,
  }) => baseAxios.post('/api/admin/auth/send-recovery-email', data).then((response: any) => response.data),

  checkGuid: (data: {
    guid: string,
  }) => baseAxios.post('/api/admin/auth/guid-check', data).then((response: any) => response.data),

  resetPassword: (data: {
    guid: string,
    password: string,
    newPassword: string,
  }) => baseAxios.post('/api/admin/auth/reset-password', data).then((response: any) => response.data),
};
