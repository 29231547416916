import { FormikValues } from "formik";
import adminAuthorizedAxios from './adminAuthorizedAxios';
import adminJwtAuthorizedAxios from "./adminJwtAuthorizedAxios";

export default {
  loginByGuid: (guid: string): Promise<any> => adminAuthorizedAxios.get(`/api/coach/loginByGuid/${guid}`)
    .then((responseData) => responseData.data),
  getCoach: () => adminAuthorizedAxios.get('/api/coach/settings'),
  getUsers: (): Promise<any> => adminAuthorizedAxios.get('/api/coach/getUsers')
    .then((responseData) => responseData.data),
  getTeams: (): Promise<any> => adminAuthorizedAxios.get('/api/coach/getTeams')
    .then((responseData) => responseData.data),
  addTeam: (name: string) => adminAuthorizedAxios.post('/api/coach/addTeam', { name }),
  editTeam: (teamId: number, name: string) => adminAuthorizedAxios.put(`/api/coach/editTeam/${teamId}`, { name }),
  deleteTeam: (teamId: number) => adminAuthorizedAxios.delete(`/api/coach/deleteTeam/${teamId}`),
  uploadTeamLogo: (teamId: number, data: FormData) => adminAuthorizedAxios.post(
    `/api/coach/uploadTeamLogo/${teamId}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteTeamLogo: (teamId: number) => adminAuthorizedAxios.delete(`/api/coach/deleteTeamLogo/${teamId}`),
  getAvailablePlans: () => adminAuthorizedAxios.get('api/coach/availablePlans'),
  addNewMember: (teamId: number, customerEmail: string, customerEmailRetype: string) => adminJwtAuthorizedAxios.post(`/api/admin/v1/coach/teams/${teamId}/invite-customer`, {
    customerEmail,
    customerEmailRetype,
  }),
  getOrders: () => adminAuthorizedAxios.get('/api/coach/tests'),
  requestPermissions: (customerId: number, teamId: number) => adminAuthorizedAxios.get(`/api/coach/request-permissions/${customerId}/${teamId}`),
  moveToTeam: (customerId: number, teamIdFrom: number, teamIdTo: number) => adminAuthorizedAxios.get(`/api/coach/move-to-team/${customerId}/${teamIdFrom}/${teamIdTo}`),
  assignTestToUser: (testId: number, userId: number) => adminAuthorizedAxios.post(`/api/coach/assignTestToUser/${testId}/${userId}`),
  deleteUserFromTeam: (userId: number, teamId: number) => adminAuthorizedAxios.delete(`/api/coach/deleteUser/${userId}/${teamId}`),
  addUserToTeam: (userId: number, teamId: number) => adminAuthorizedAxios.get(`api/coach/addUserToTeam/${userId}/${teamId}`),
};
