import throttle from 'lodash.throttle';
import { createBaseAxios } from '../baseAxios';
import AdminAuthTokenProvider from './adminAuthTokenProvider';
import adminAuthApi from './adminAuthApi';

const adminAuthAxios = createBaseAxios();

export const fetchAdminJwtToken = throttle(async () => {
  try {
    const res = await adminAuthApi.getJwtTokenByCookies();
    AdminAuthTokenProvider.update(res.access_token, res.expires_in);
  } catch {
  }
}, 5000);

adminAuthAxios.interceptors.request.use(async (config) => {
  let token = AdminAuthTokenProvider.get();
  const expiresIn = AdminAuthTokenProvider.getSecondsToExpiration();

  if (expiresIn < 0) {
    await fetchAdminJwtToken();
    token = AdminAuthTokenProvider.get();
  } else if (expiresIn < 300) {
    // fetch new token 5 mins before expiration
    fetchAdminJwtToken();
  }

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

adminAuthAxios.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  if (error.response.status === 403) {
    alert('Please enable corporate VPN and reload the page to continue.');
  }
  return Promise.reject(error);
});

export default adminAuthAxios;
